<template>
  <v-card>
    <v-card-title>
      {{ $t('governorates') }}
      <v-spacer></v-spacer>




    </v-card-title>

    <v-data-table
      class="dd"
      :headers="headers"
      :items="governorates"
      :options.sync="options"
      :server-items-length="governoratesTotal"
      :loading="loading"
      :disable-filtering="true"
      :footer-props="{
        showFirstLastPage: true,
        itemsPerPageOptions:[10, 20, 50, 100, 500],
        itemsPerPageText: $t('dataTable.itemsPerPageText'),
        pageText: $t('dataTable.pageText'),
        itemsPerPageAllText: $t('dataTable.itemsPerPageAllText'),
      }"
      :hide-default-header="!$vuetify.breakpoint.mobile"
      @update:page="fetch"
      @update:items-per-page="fetch"
    >
      <template v-if="!$vuetify.breakpoint.mobile" v-slot:header="{ props }">
        <datatable-header :props="props" :options.sync="options" :translation-key="translationKey" @update:filters="fetch"></datatable-header>
      </template>
      <template v-slot:header.actions="{ header }">
        {{ header.text }}
      </template>

      <template v-slot:item.new_numbers="{ item }">

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color='#00bcd4'
              label
              class='mx-1'
              small
              v-bind="attrs"
              v-on="on"
            >
              {{item.s_new_numbers}}
            </v-chip>

          </template>
          <span> طلبة {{item.name}} </span>
        </v-tooltip>



        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color='#00bcd4'
              label
              class='mx-1'
              small
              v-bind="attrs"
              v-on="on"
            >
              {{item.t_new_numbers}}
            </v-chip>

          </template>
          <span> اساتذة {{item.name}} </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color='#00bcd4'
              label
              class='mx-1'
              small
              v-bind="attrs"
              v-on="on"
            >
              {{item.d_new_numbers}}
            </v-chip>

          </template>
          <span> سواق {{item.name}} </span>
        </v-tooltip>



      </template>
      <template v-slot:item.locked_numbers="{ item }">



        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color='error'
              label
              class='mx-1'
              small
              v-bind="attrs"
              v-on="on"
            >
              {{item.s_locked_numbers}}
            </v-chip>

          </template>
          <span> طلبة {{item.name}} </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color='error'
              label
              class='mx-1'
              small
              v-bind="attrs"
              v-on="on"
            >
              {{item.t_locked_numbers}}
            </v-chip>

          </template>
          <span> اساتذة {{item.name}} </span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              color='error'
              label
              class='mx-1'
              small
              v-bind="attrs"
              v-on="on"
            >
              {{item.d_locked_numbers}}
            </v-chip>

          </template>
          <span> سواق {{item.name}} </span>
        </v-tooltip>


      </template>
      <template v-slot:item.active_numbers="{ item }">
        <v-chip
          color='success'
          label
          class='mx-1'
          small
        >
          {{item.s_active_numbers}}
        </v-chip>
        <v-chip
          color='success'
          label
          class='mx-1'
          small
        >
          {{item.t_active_numbers}}
        </v-chip>
        <v-chip
          color='success'
          label
          class='mx-1'
          small
        >
          {{item.d_active_numbers}}
        </v-chip>




      </template>
      <template v-slot:item.sent_numbers="{ item }">
        <v-chip
          color='primary'
          label
          class='mx-1'
          small
        >
          {{item.s_sent_numbers}}
        </v-chip>
        <v-chip
          color='primary'
          label
          class='mx-1'
          small
        >
          {{item.t_sent_numbers}}
        </v-chip>
        <v-chip
          color='primary'
          label
          class='mx-1'
          small
        >
          {{item.d_sent_numbers}}
        </v-chip>

      </template>



      <template v-slot:item.actions="{ item }">
        <v-btn
          class="mx-2"
          color="info"
          x-small

          @click="showSchools( item.id)"
        >
          {{ $('showSchools') }}
          <v-icon
            dark
            right
          >
            mdi-town-hall
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          color="info"
          x-small
          @click="showDirectorates( item.id)"
        >
          {{ $('showDirectorates') }}

          <v-icon
            dark
            right
          >
            mdi-trophy-award
          </v-icon>

        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import axios from '@axios'
import DatatableHeader from '@/components/dataTables/DatatableHeader.vue'

import { ObjectToQuery } from '@/plugins/helper';
import { getFilters, getOptions } from '@/components/dataTables/helper'
import router from '@/router'

export default {
  name: 'Governorates',
  components: { DatatableHeader },
  data: () => ({
    translationKey: 'pages.governorates',
    filters: false,
    governorates: [],
    governoratesTotal: 0,
    loading: true,
    options: {},
    tempOptions: '',
    search: '',
    dialogData: {
      dialog: false,
      type: 'create',
      id: null,
    },
    headers: [
      {
        text: 'name',
        align: 'center',
        sortable: false,
        value: 'name',
        sort: true,
        csort:true,
        filter: {
          menu: false,
          type: 'text',
          methods: ['contains', 'startWith', 'endWith', 'equal', 'notEqual'],
        },
      },
      {
        text: 'new_numbers',
        align: 'center',
        sortable: true,
        value: 'new_numbers',
        sort: true,
        filter: {
          menu: false,
          sort: true,
          type: 'number-search',
          methods: ['greater', 'equal', 'less'],
          my_options: ['students', 'teachers', 'drivers'],
        },
      },
      {
        text: 'active_numbers',
        align: 'center',
        sortable: false,
        value: 'active_numbers',
        sort: true,
        filter: {
          menu: false,
          type: 'number-search',
          methods: ['greater', 'equal', 'less'],
          my_options: ['students', 'teachers', 'drivers'],
        },
      },
      {
        text: 'locked_numbers',
        align: 'center',
        sortable: false,
        value: 'locked_numbers',
        sort: true,
        filter: {
          menu: false,
          type: 'number-search',
          methods: ['greater', 'equal', 'less'],
          my_options: ['students', 'teachers', 'drivers'],

        },
      },
      {
        text: 'sent_numbers',
        align: 'center',
        sortable: false,
        value: 'sent_numbers',
        sort: true,
        filter: {
          menu: false,
          type: 'number-search',
          methods: ['greater', 'equal', 'less'],
          my_options: ['students', 'teachers', 'drivers'],

        },

      },

      {
        text: 'actions',
        value: 'actions',
        align: 'center',
      },
    ],
  }),
  mounted() {
    this.options = getOptions(this.$route, this.options);
    this.fetch()
  },

  methods: {
    async fetch() {
      if (JSON.stringify(this.options) !== this.tempOptions) {
        this.tempOptions = JSON.stringify(this.options);
        await this.$router.replace({ query: this.options }).catch(() => {});
      }
      window.scrollTo(0, 0);
      localStorage.setItem(`itemsPerPage_${this.$route.name}`, this.options.itemsPerPage);
      this.loading = true;
      const filters = getFilters(this.headers);
      const query = ObjectToQuery({
        ...this.options,
        filters,
      })
      const response = await axios.get(`/governorates?${query}`);
      this.governorates = response.data.data.items
      this.governoratesTotal = response.data.data.meta.total
      this.loading = false
    },

    async showSchools(id) {
      await router.push(`/schools_gov/${id}`);
    },
    async showDirectorates(id) {
      await router.push(`/directorates/${id}`);
    },
    $(key, parameters = null) {
      return this.$t(`${this.translationKey}.${key}`, parameters);
    },
  },

}
</script>
